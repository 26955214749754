const isLogin = ()=>{
   const storageData = localStorage.getItem('userDetails') ? JSON.parse(localStorage.getItem('userDetails')):'';
   return !!storageData;

}

const storageData = ()=>{
   const storageData = localStorage.getItem('userDetails') ? JSON.parse(localStorage.getItem('userDetails')):{};
      return storageData;
}

// Key to be deleted
const keyToDelete = 'userDetails';
const lastDeletionDateKey = 'lastDeletionDate';

// Function to check and delete the key
function deleteKeyOnceADay() {
  const currentDate = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format
  const lastDeletionDate = localStorage.getItem(lastDeletionDateKey);

  if (lastDeletionDate !== currentDate) {
    // If the current date is different from the last deletion date, delete the key
    localStorage.removeItem(keyToDelete);
    // Update the last deletion date to the current date
    localStorage.setItem(lastDeletionDateKey, currentDate);
    console.log(`Deleted key "${keyToDelete}" and updated deletion date.`);
    window.location.reload();
  } else {
    console.log(`Key "${keyToDelete}" has already been deleted today.`);
  }
}

// Call the function to check and delete the key

export {isLogin, storageData, deleteKeyOnceADay}