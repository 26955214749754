import React from "react";

const ComingSoon = () => {
  return (
    
    <>
   {
    alert("Hello")
   }
      <section className="w-full h-auto">
        <div className="videosec">
          <h1>Coming Soon</h1>
          <h3>The #1 Dating Platform in the World</h3>
          <div>
            <video
              id="banner4-video"
              width="220"
              height="140"
              poster="/assets/fallback.jpg"
              controls
              loop 
              autoPlay
              style={{
                objectFit: "cover",
                width: "100%",
                height: "75%",
                position: "static",
              }} // Inline styles
            >
              <source src="/assets/bannervedio.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </section>
    </>
  );
};

export default ComingSoon;
