let baseUrl = "https://api.khubero.com/maavatar/api/v1/";
if(window.location.href.includes("localhost")){
    //baseUrl = "http://localhost:3000/maavatar/api/v1/"
}

const routes = {
    create_account:{
        endpoint:"https://api.avaturn.me/api/v1/users/new",
        method:"post"
    },
    new_session:{
        endpoint:"https://api.avaturn.me/api/v1/sessions/new",
        method:"post"
    },
    uploadUrl:{
        endpoint:baseUrl+"avaturn/image-upload-url",
        method:"post"
    },
    signup:{
        endpoint:baseUrl+"user/signup",
        method:"post"
    },
    avaturnUpdate:{
        endpoint:baseUrl+"user/avaturn",
        method:"put"
    },
    avaturnHook:{
        endpoint:baseUrl+"user/export",
        method:"post"
    },
    avaturnCreateAccount:{
        endpoint:baseUrl+"avaturn/create-account",
        method:"post"
    },
    avaturnCreateSession:{
        endpoint:baseUrl+"avaturn/create-session",
        method:"post"
    },
    avaturnExport:{
        endpoint:baseUrl+"avaturn/create-export",
        method:"post"
    },
    avaturnRender:{
        endpoint:baseUrl+"avaturn/create-render",
        method:"post"
    },
    avaturnUploadImage:{
        endpoint:baseUrl+"avaturn/upload-image",
        method:"post"
    }
}

export {routes}