import React from 'react'
import "../../scss/_campaign.scss";
import { Link } from 'react-router-dom';
import "animate.css";

const Footer = () => {
  return (
    <React.Fragment>
        <div className="footer animate__animated animate__backInUp">
            <div className="top">
                <Link to="/">MaAvatar2024</Link>
                <Link to="/"><img src="/favicon.png" width={40} alt="logo" /></Link>
                <div className="footer-social-icon-links">
                    <Link to="https://x.com/MaAvatarmeta" className='footer-link'><img src="/campaign/Footer-items/twitter.svg" width={20} alt="logo" /></Link>
                    <Link to="https://www.linkedin.com/company/maavatar/" className='footer-link'><img src="/campaign/Footer-items/linkedin.svg" width={20} alt="logo" /></Link>
                    <Link to="https://www.facebook.com/MaAvatar" className='footer-link'><img src="/campaign/Footer-items/fb.svg" width={20} alt="logo" /></Link>
                    <Link to="" className='footer-link'><img src="/campaign/Footer-items/git.svg" width={20} alt="logo" /></Link>
                    <Link to="" className='footer-link'><img src="/campaign/Footer-items/victory.png" width={20} alt="logo" /></Link>
                    <Link to="" className='footer-link'><img src="/campaign/Footer-items/boll.svg" width={20} alt="logo" /></Link>
                </div>
            </div>
            <div className='hr'></div>
            <div className="down">
                <Link className='down-link' to="">© 2024 MaAvatar. All rights reserved.</Link>
            </div>
        </div>
    </React.Fragment>
  )
}

export default Footer