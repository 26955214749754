import { useState } from "react";
import { RxCross1 } from "react-icons/rx";
import data from "./leaderboard.json";
import "animate.css";
import { Link } from "react-router-dom";

function Profile({ index, toggleProfile, setToggleProfile }) {
  let details = data[index] ?? "";
  // const [notRewards, setNotRewards] = useState(false);

  return (
    <>
      {toggleProfile && (
        <div
          className="animate__animated animate__fadeIn"
          style={{
            height: "450px",
            width: "70%",
            background: "#202020",
            position: "fixed",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 9999,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            boxShadow: "#fff 0px 0px 40px",
          }}
        >
          <button
            onClick={() => {
              setToggleProfile(!toggleProfile);
            }}
            style={{
              position: "absolute",
              top: "25px",
              right: "20px",
              background: "none",
              border: "none",
            }}
          >
            <RxCross1 size={25} />
          </button>
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              columnGap: 15,
            }}
          >
            <img
              className="animate__animated animate__backInLeft"
              src={details?.profile_Image}
              alt="image"
              height={70}
              width={70}
              style={{ borderRadius: "50%" }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "start",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <p
                className="animate__animated animate__backInLeft"
                style={{ fontSize: "20px", fontWeight: "bolder" }}
              >
                {details?.player}
              </p>
              <p
                className="animate__animated animate__backInLeft"
                style={{ fontWeight: "500" }}
              >
                <span style={{ color: "#01CDE7" }}>{details?.rank}</span> Rank
              </p>
              <p
                className="animate__animated animate__backInLeft"
                style={{ fontWeight: "500" }}
              >
                {details?.xp} XP
              </p>
              <Link
                target="_blank"
                to={details?.twitter}
                style={{ color: "#fff", marginTop: "5px" }}
              >
                <p
                  className="animate__animated animate__backInLeft"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    columnGap: "5px",
                    background: "#383838",
                    borderRadius: "24px",
                    fontSize: "11px",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    paddingTop: "2px",
                    paddingBottom: "2px",
                  }}
                >
                  <img
                    className="animate__animated animate__backInLeft"
                    src="/campaign/Footer-items/twitter.svg"
                    width={15}
                    alt="logo"
                  />
                  {details?.twitter_username}
                </p>
              </Link>
            </div>
            <img
              className="animate__animated animate__backInLeft"
              src={
                (details?.badge === "maav darlings" &&
                  "/campaign/maav_darlings.png") ||
                (details?.badge === "maav og" && "/campaign/maav_og.png") ||
                (details?.badge === "maav hunters" &&
                  "/campaign/maav_hunters.png") ||
                (details?.badge === "maav goat" && "/campaign/maav_goat.png") ||
                (details?.badge === "maav stars" && "/campaign/maav_stars.png")
              }
              alt="image"
              height={100}
              width={
                details?.badge === "maav darlings" ||
                details?.badge === "maav hunters"
                  ? 90
                  : 100
              }
              style={{ borderRadius: "50%" }}
            />
          </div>
          <div
            className="hr"
            style={{ height: "1px", width: "90%", background: "purple" }}
          ></div>

          <p
            style={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            {/* {notRewards ? "You are not eligible for rewards!" : "Perks" && setNotRewards(false)} */}
          </p>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            {details?.perks &&
              details?.perks.map((item, index) => {
                if (item?.type === "") return "";
                return (
                  <img
                    className="animate__animated animate__backInRight"
                    key={index}
                    src={
                      (item?.type === "NFT MYSTERY BOX" &&
                        "/campaign/card 2.png") ||
                      (item?.type === "Early Access" &&
                        "/campaign/card1.png") ||
                      (item?.type === "Entry to Adult Beach" &&
                        "/campaign/card3.png") ||
                      (item?.type === "Loot BOX" && "/campaign/card2.png")
                    }
                    width="20%"
                    alt="Rewards"
                  />
                );
              })}
          </div>
        </div>
      )}
    </>
  );
}

export default Profile;
