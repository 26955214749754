let server_url = "https://api.khubero.com/";

if( window?.location?.href.includes("localhost"))
    server_url = "http://localhost:8080/"


const callAPI = async(endpoint, payload = {}, method = "POST")=>{
     const storageData = localStorage.getItem('userDetails') ? JSON.parse(localStorage.getItem('userDetails')):{};
     const userEmail = storageData.email;
  if(userEmail){
    payload.email = userEmail;
  }
        
      


return new Promise(async(resolve, reject)=>{
    const rawResponse = await fetch(endpoint, {
        method: method,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          // 'Authorization': `Bearer ${jwt}`
        },
        body: JSON.stringify(payload)
      });

      if(rawResponse.status === 401){
        localStorage.removeItem('userDetails');
        window.location.href="/";
        
      }
      console.log("content", rawResponse)

      const content = await rawResponse.json();
      resolve(content);
})
}
export {server_url, callAPI}