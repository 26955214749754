import { React, useEffect, useState, createContext } from "react";
import AOS from "aos";
import { Route, Routes } from "react-router-dom";

import routes from "./pages";
import Page404 from "./pages/404";
// import Header from "./components/header/Header";
import Home01 from "./pages/Home01";
import { AppContext } from "./components/blockchain/context";
import Marketplace from "./pages/Marketplace";
import Avatar from "./components/avatar";
// import Signin from './pages/Signin';
import Signup from "./pages/Signup";
import Contact from "./pages/Contact";
import AvatarCreation from "./NewPages/AvatarCreation";
import AvatarCreation2 from "./NewPages/AvatarCreation2";

import Profile from "./NewPages/Profile";
import AvatarCustomization from "./NewPages/AvatarCustomization";
// import Campaign from './pages/Campaign';
// import Meta from './components/Meta';
import Term from "./Term";
import Privacy from "./Privacy";
import AvaturnButton from "./components/header/AvaturnButton";
import Userdetails from "./NewPages/Userdetails";
import Userdetails2 from "./NewPages/Userdetails2";
// import UploadImage from './components/gltf/UploadImage';
import MultiPartUploadForm from "./components/gltf/Test";
import Gltf from "./components/gltf/GltfView";
import ImageUpload from "./components/ImageUpload";
import BlogDetails1 from "./pages/BlogDetails1";
import BlogDetails2 from "./pages/BlogDetails2";
import BlogDetails3 from "./pages/BlogDetails3";

import Blog from "./pages/Blog";
import { deleteKeyOnceADay } from "./common/utils";
import ComingSoon from "./pages/ComingSoon";
import Campaign from "./components/campaign";
import LandingPage from "./components/landing_page";

function App() {
  const [data, setData] = useState();
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
    deleteKeyOnceADay();
  }, []);

  return (
    <AppContext.Provider value={{ data, setData }}>
      {/* {!window.location.href.includes("upload-image") && <Header />} */}
     
      <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/campaign" element={<Campaign />} />
        {/* <Route path="/commingsoon" element={<ComingSoon />} /> */}
        {/* <Route path="/signup" element={<Signup />} exact />
        <Route path="/AvatarCreation" element={<AvatarCreation />} exact />
        <Route path="/AvatarCreation2" element={<AvatarCreation2 />} exact />
        <Route path="/Profile" element={<Profile />} exact /> */}
        {/* <Route
          path="/AvatarCustomization"
          element={<AvatarCustomization />}
          exact
        /> */}
        {/* <Route path="/AvaturnButton" element={<AvaturnButton />} exact />
        <Route path="/Userdetails" element={<Userdetails />} exact />
        <Route path="/Userdetails2" element={<Userdetails2 />} exact />
        <Route path="/Privacy" element={<Privacy />} exact />
        <Route path="/Term" element={<Term />} exact />
        <Route path="/modal" element={<Gltf />} exact />
        <Route path="/upload-image" element={<ImageUpload />} exact />
        <Route path="/upload-imagee" element={<ImageUpload />} exact />
        <Route path="/Blog" element={<Blog />} exact />
        <Route path="/BlogDetails1" element={<BlogDetails1 />} exact />
        <Route path="/BlogDetails2" element={<BlogDetails2 />} exact />
        <Route path="/BlogDetails3" element={<BlogDetails3 />} exact />
         <Route path="*" element={<Page404 />} /> **/}
      </Routes>
    </AppContext.Provider>
  );
}

export default App;
